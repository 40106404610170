import React from "react"
import ReactDOM from "react-dom"
import * as api from "./api"
import {
    colorHash,
    ERC20Methods,
    ERC721Methods,
    MulticallMethods,
    uniqBy,
} from "./api"

function Layout({ children }) {
    return (
        <div className="container">
            <div className="header">
                <img
                    src={new URL("./icon.png", import.meta.url).toString()}
                    alt="icon"
                />
                <div>SolaCoin</div>
            </div>
            {children}
        </div>
    )
}

function App() {
    let parts = location.pathname.slice(1).split("/")
    // /bsc/account/:account
    if (parts.length === 3 && parts[1] === "account") {
        const network = api.networks.find((v) => v.key === parts[0])
        let account = parts[2]
        if (!network)
            return (
                <Layout>
                    <div className="content">
                        <p>Network "{parts[0]}" not recognized</p>
                    </div>
                </Layout>
            )
        return (
            <Layout>
                <AccountHoldings account={account} network={network} />
            </Layout>
        )
        // return <TokenGallery2 account={account} network={network} />
        // return
    } else if (parts.length === 4 && parts[1] === "nft") {
        // /bsc/nft/:contract/:token
        const network = api.networks.find((v) => v.key === parts[0])
        if (!network) return <div>Network "{parts[0]}" not recognized</div>
        return (
            <Layout>
                <div className="nft-viewer">
                    <div className="nfts-inner">
                        <TokenViewerSmall
                            contractAddress={parts[2]}
                            tokenID={parts[3]}
                            network={network}
                        />
                    </div>
                </div>
            </Layout>
        )
    }
    return <Homepage />
}
function TokenViewerSmall({ contractAddress, tokenID, network }) {
    const [data, setData] = React.useState<{
        logs: {
            topics: string[]
            address: string
        }[]
        results: Record<string, string>
    }>(null)
    React.useEffect(() => {
        ;(async () => {
            // const address = contractAddress.slice(2).padStart(64, "0")
            // console.log(address)
            const address = contractAddress.toLowerCase()
            let calls = []
            calls.push(address + ERC721Methods.name)
            calls.push(address + ERC721Methods.symbol)
            calls.push(
                address + ERC721Methods.ownerOf + tokenID.padStart(64, "0")
            )
            calls.push(
                address + ERC721Methods.tokenURI + tokenID.padStart(64, "0")
            )
            console.log(calls)
            const map = await api.multicallMap(network, calls)
            console.log(map)
            setData({
                logs: [],
                results: map,
            })
        })()
    }, [])
    if (!data) return <Loading />
    return (
        <TokenViewerSmaller
            data={data}
            network={network}
            contractAddress={contractAddress}
            tokenID={tokenID}
        />
    )
    // return <div>{tokenID.padStart(64, "0")}</div>
}

function formatAmount(token: string, amount: number) {
    if (amount.toFixed(6).length < 9) {
        return amount.toFixed(6)
    } else {
        return amount.toFixed(2)
    }
}

function AccountHoldings({
    account,
    network,
}: {
    account: string
    network: api.Network
}) {
    const [data, setData] = React.useState<{
        logs: {
            topics: string[]
            address: string
        }[]
        results: Record<string, string>
    }>(null)
    React.useEffect(() => {
        ;(async () => {
            const d = await api.fetchTokensAndNFTs(network, account)
            console.log(d)
            setData(d)
        })()
    }, [account, network.key])

    const fungibleTokenAddresses =
        data &&
        Array.from(
            new Set(
                data.logs
                    .filter((k) => k.topics.length === 3)
                    .map((k) => k.address)
            )
        )
            .filter(
                (k) =>
                    !isNaN(
                        parseInt(
                            data.results[
                                k +
                                    ERC20Methods.balanceOf +
                                    account.slice(2).padStart(64, "0")
                            ],
                            16
                        ) /
                            10 **
                                parseInt(
                                    data.results[k + ERC20Methods.decimals],
                                    16
                                )
                    )
            )
            .sort((a, b) => {
                const isDefaultToken = (address) =>
                    network.defaultTokens?.some(
                        (k) => k.toLowerCase() === address.toLowerCase()
                    )
                return +isDefaultToken(b) - +isDefaultToken(a)
            })

    const nftList =
        data &&
        uniqBy(
            data.logs
                .filter((k) => k.topics.length === 4)
                .filter(
                    (k) =>
                        data.results[
                            k.address +
                                ERC721Methods.ownerOf +
                                k.topics[3].slice(2)
                        ].toLowerCase() ===
                        account.slice(2).padStart(64, "0").toLowerCase()
                ),
            (k) => k.address + k.topics[3]
        ).sort((a, b) => {
            const isDefaultToken = (address) =>
                network.defaultNFTs?.some(
                    (k) => k.toLowerCase() === address.toLowerCase()
                )
            return +isDefaultToken(b.address) - +isDefaultToken(a.address)
        })
    return (
        <div className="account-holdings">
            <div className="account-details">
                <div className="content">
                    <button
                        className="button"
                        onClick={(e) => {
                            const address = api.SOLA_ADDR
                            e.preventDefault()
                            api.addToWallet({
                                network,
                                address: "0x" + address.slice(-40),
                                symbol: api.parseString(
                                    data.results[address + ERC20Methods.symbol]
                                ),
                                decimals: parseInt(
                                    data.results[
                                        address + ERC20Methods.decimals
                                    ],
                                    16
                                ),
                                icon: api.BSC.customTokenIcons[address],
                            }).then((k) =>
                                alert("SolaCoin added to MetaMask wallet!")
                            )
                        }}
                    >
                        Add SolaCoin to MetaMask
                    </button>
                </div>
                {!data ? (
                    <Loading />
                ) : (
                    <div className="coins-box">
                        <div className="fungible-token-held">
                            <div className="token-balance">
                                <img
                                    crossOrigin="anonymous"
                                    src={network.logo}
                                    style={{
                                        background: `linear-gradient(${colorHash(
                                            network.key
                                        )},${colorHash(network.key + 2)})`,
                                    }}
                                />
                                <div className="balance">
                                    {formatAmount(
                                        "ETH",

                                        parseInt(
                                            data.results[
                                                network.multicall2 +
                                                    MulticallMethods.getEthBalance +
                                                    account
                                                        .slice(2)
                                                        .padStart(64, "0")
                                            ],
                                            16
                                        ) / 1e18
                                    )}
                                </div>{" "}
                                <div className="symbol">
                                    {network.nativeSymbol}
                                </div>
                            </div>
                        </div>

                        {fungibleTokenAddresses.map((address: string) => {
                            const ercImage =
                                network.customTokenIcons?.[
                                    "0x" + address.slice(-40).toLowerCase()
                                ] ||
                                network.tokenIcons?.replace(
                                    "ADDRESS",
                                    api.toChecksumAddress(address.slice(-40))
                                )

                            return (
                                <div
                                    className="fungible-token-held"
                                    key={address}
                                >
                                    <div className="token-balance">
                                        <img
                                            crossOrigin="anonymous"
                                            style={{
                                                background: `linear-gradient(${colorHash(
                                                    address
                                                )},${colorHash(
                                                    address + 2,
                                                    50,
                                                    60
                                                )})`,
                                            }}
                                            src={ercImage}
                                            data-src={ercImage}
                                            onError={(e) => {
                                                ;(
                                                    e.target as HTMLImageElement
                                                ).src =
                                                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                            }}
                                        />

                                        <div className="balance">
                                            {formatAmount(
                                                address,
                                                parseInt(
                                                    data.results[
                                                        address +
                                                            ERC20Methods.balanceOf +
                                                            account
                                                                .slice(2)
                                                                .padStart(
                                                                    64,
                                                                    "0"
                                                                )
                                                    ],
                                                    16
                                                ) /
                                                    10 **
                                                        parseInt(
                                                            data.results[
                                                                address +
                                                                    ERC20Methods.decimals
                                                            ],
                                                            16
                                                        )
                                            )}
                                        </div>
                                        <div className="symbol">
                                            <a
                                                className="subtle"
                                                href={
                                                    network.explorer + address
                                                }
                                            >
                                                {api.parseString(
                                                    data.results[
                                                        address +
                                                            ERC20Methods.symbol
                                                    ]
                                                )}
                                            </a>
                                        </div>
                                        <div className="fungible-actions">
                                            {network.swapLink && (
                                                <a
                                                    href={
                                                        network.swapLink +
                                                        "0x" +
                                                        address.slice(-40)
                                                    }
                                                    target="_blank"
                                                    rel="noopener"
                                                    className="swap subtle"
                                                >
                                                    Trade
                                                </a>
                                            )}
                                            <a
                                                href="#"
                                                className="swap subtle"
                                                title="Add to Metamask"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    const symbol =
                                                        api.parseString(
                                                            data.results[
                                                                address +
                                                                    ERC20Methods.symbol
                                                            ]
                                                        )
                                                    const decimals = parseInt(
                                                        data.results[
                                                            address +
                                                                ERC20Methods.decimals
                                                        ],
                                                        16
                                                    )
                                                    api.addToWallet({
                                                        network,
                                                        address:
                                                            "0x" +
                                                            address.slice(-40),
                                                        symbol: symbol,
                                                        decimals: decimals,
                                                        icon: ercImage,
                                                    }).then((k) =>
                                                        alert(
                                                            symbol +
                                                                " added to MetaMask wallet!"
                                                        )
                                                    )
                                                }}
                                            >
                                                Add
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <div className="nfts">
                <div className="nfts-inner">
                    {!data ? (
                        <Loading />
                    ) : nftList.length === 0 ? (
                        <div>
                            <div className="fancy-label">
                                <div className="name">No NFTs Found</div>
                                <div className="description">
                                    No NFTs were found associated with this
                                    account on Binance Smart Chain.
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {nftList.map((k, i) => (
                                <TokenViewerSmaller
                                    data={data}
                                    key={i}
                                    network={network}
                                    contractAddress={k.address}
                                    tokenID={
                                        k.topics[3]
                                            .slice(2)
                                            .replace(/^0+/, "") || "0"
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

function TokenViewerSmaller({ data, contractAddress, tokenID, network }) {
    const [nft, setNFT] = React.useState(null)

    React.useEffect(() => {
        const url = api.parseString(
            data.results[
                contractAddress +
                    ERC721Methods.tokenURI +
                    tokenID.padStart(64, "0")
            ]
        )
        if (url)
            fetch(
                url
                    .replace("ipfs://", api.IPFS_GATEWAY)
                    .replace("https://ipfs.io/", api.IPFS_GATEWAY)
            )
                .then((k) => k.json())
                .then((tokendata) => {
                    // Object.keys(tokendata).forEach(k => tokendata[k] = tokendata[k].description || tokendata[k])
                    console.log(tokendata)
                    try {
                        const nft = tokendata.properties
                            ? {
                                  image: tokendata.properties.image.description,
                                  name: tokendata.properties.name.description,
                                  description:
                                      tokendata.properties.description
                                          .description,
                              }
                            : tokendata

                        setNFT(nft)
                    } catch (err) {
                        console.error(err)
                    }
                })
    }, [contractAddress, tokenID])

    const owner =
        data.results[
            contractAddress + ERC721Methods.ownerOf + tokenID.padStart(64, "0")
        ]

    return (
        <div className="token-viewer-small">
            {nft && (
                <div className="fancy-frame">
                    <img
                        src={nft.image
                            ?.replace("ipfs://", api.IPFS_GATEWAY)
                            .replace("https://ipfs.io/", api.IPFS_GATEWAY)}
                        crossOrigin="anonymous"
                    />
                </div>
            )}
            <div className="fancy-label">
                <div className="name">
                    <a
                        className="subtle"
                        href={
                            "/" +
                            network.key +
                            "/nft/" +
                            contractAddress +
                            "/" +
                            tokenID
                        }
                    >
                        {nft
                            ? nft.name
                            : parseInt(tokenID, 16) < 1e9
                            ? parseInt(tokenID, 16)
                            : tokenID.slice(-40)}
                    </a>
                </div>
                <div className="contract">
                    <a
                        className="subtle"
                        href={network.explorer + contractAddress}
                    >
                        {api.parseString(
                            data.results[contractAddress + ERC20Methods.name]
                        ) || contractAddress}
                    </a>
                </div>
                <div className="description">
                    {owner && (
                        <>
                            <p>
                                From the private collection of{" "}
                                <a
                                    className="subtle"
                                    href={
                                        "/" +
                                        network.key +
                                        "/account/0x" +
                                        owner.slice(-40)
                                    }
                                >
                                    0x{owner.slice(-40)}
                                </a>
                            </p>
                        </>
                    )}
                    {nft?.description && <p>{nft.description}</p>}

                    <details>
                        <summary>
                            How to add this NFT to Metamask Mobile
                        </summary>
                        <ol>
                            <li>
                                Click "Import NFTs" on the bottom of the "NFTs"
                                tab of your Wallet
                            </li>
                            <li>
                                <b>Address:</b> {contractAddress}
                            </li>
                            <li>
                                <b>ID:</b> {parseInt(tokenID, 16)}
                            </li>
                        </ol>
                        <p></p>
                    </details>
                </div>
            </div>
        </div>
    )
}

function Homepage() {
    return (
        <Layout>
            <div className="content">
                {typeof window.ethereum === "undefined" ? (
                    isMobileOrTablet() ? (
                        <>
                            <p>
                                In order to <b>recieve and send SolaCoin</b>,
                                make sure you have the{" "}
                                <a href="https://metamask.app.link/wc?uri=https://nftview.art/">
                                    MetaMask Mobile App
                                </a>{" "}
                                installed.
                            </p>
                            <p>
                                Open the <b>MetaMask</b> app, and select the
                                built-in <b>Browser</b> from the left side menu.
                                Then navigate to this page (solacoin.app), and
                                follow instructions.
                            </p>
                        </>
                    ) : (
                        <p>
                            In order to <b>recieve and send SolaCoin</b>, please
                            make sure you have <b>MetaMask</b> installed and
                            enabled.
                            <div>
                                <a
                                    href="https://metamask.io/download"
                                    className="button"
                                >
                                    Click to Install <b>MetaMask</b>
                                </a>
                            </div>
                        </p>
                    )
                ) : (
                    <>
                        <p></p>

                        <button
                            className="button"
                            onClick={async () => {
                                try {
                                    const network = api.BSC

                                    const hexNet =
                                        "0x" + parseInt(network.id).toString(16)
                                    // alert("boop")
                                    const chainId =
                                        await window.ethereum.request({
                                            method: "eth_chainId",
                                            params: [],
                                        })

                                    // alert("hix")
                                    if (chainId !== hexNet) {
                                        const connectionParam = {
                                            chainId: hexNet,
                                            chainName: network.name,
                                            nativeCurrency: {
                                                name: network.nativeSymbol,
                                                symbol: network.nativeSymbol,
                                                decimals: 18,
                                            },
                                            rpcUrls: [network.rpcURL],
                                            blockExplorerUrls: [
                                                "https://bscscan.com/",
                                            ],
                                        }
                                        // alert("meet")
                                        await window.ethereum.request({
                                            method: "wallet_addEthereumChain",
                                            params: [connectionParam],
                                        })
                                        // alert("zeet")
                                        const chainId2 =
                                            await window.ethereum.request({
                                                method: "eth_chainId",
                                                params: [],
                                            })

                                        if (chainId2 !== hexNet) {
                                            return alert(
                                                "Please switch Metamask to " +
                                                    network.name
                                            )
                                        }

                                        // await delay(1000)
                                    }

                                    // let network = Object.entries(networks).find(([k, v]) => v.id == net_version)
                                    // if (!network)
                                    //     throw new Error(
                                    //         "Network ID not supported!"
                                    //     )
                                    let accts = await window.ethereum.request({
                                        method: "eth_requestAccounts",
                                    })
                                    // alert(accts[0])
                                    window.location.href =
                                        "/bsc/account/" + accts[0]
                                } catch (err) {
                                    alert(err.message)
                                }
                            }}
                        >
                            View My Wallet
                        </button>
                    </>
                )}

                <p>
                    Alernatively, you can{" "}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            const addr = prompt(
                                "Enter your Binance Smart Chain address:",
                                ""
                            )
                            if (addr) {
                                window.location.href = "/bsc/account/" + addr
                            }
                        }}
                    >
                        manually enter a wallet address
                    </a>
                </p>
            </div>
        </Layout>
    )
}

function Loading() {
    // return <div>Loading...</div>
    return (
        <div className="loading">
            <div className="lds-heart">
                <div></div>
            </div>
        </div>
    )
}

function isMobileOrTablet() {
    let check = false
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window["opera"])
    return check
}

ReactDOM.createRoot(document.getElementById("root")).render(<App />)
