import { keccak256 } from "js-sha3"

export const IPFS_GATEWAY = "https://cloudflare-ipfs.com/"
export const SOLA_ADDR = "0x30fad1f31ef58ab6aa5c0267a0290535fdbcc734"

export type Network = {
    key: string
    id: string
    name: string
    rpcURL: string
    etherscanAPI?: string
    apikey?: string
    explorer: string
    nativeSymbol: string
    multicall2: string
    tokenIcons?: string
    swapLink?: string
    ensreverse?: string
    isDefaultChain?: boolean
    logo: string
    maxLogRange?: number
    defaultTokens?: string[]
    defaultNFTs?: string[]
    customTokenIcons?: Record<string, string>
    requireJSON?: boolean
    tokenIconList?: string
}
export const networks: Network[] = [
    {
        key: "mainnet",
        id: "1",
        name: "Ethereum Mainnet",
        rpcURL: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        etherscanAPI: "https://api.etherscan.io/api",
        apikey: "YMM3SCKG9P6ISXPX8YTSP32HRV5G2A3AVI",
        explorer: "https://etherscan.io/token/",
        nativeSymbol: "ETH",
        multicall2: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        tokenIcons:
            "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/ADDRESS/logo.png",
        swapLink: "https://app.uniswap.org/#/swap?outputCurrency=",
        ensreverse: "0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C",
        isDefaultChain: true,
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
        // https://pancakeswap.finance/swap?outputCurrency=0x9ff25257a49c722f5a39f0942a0924bbc53b4f77
    },
    {
        key: "bsc",
        id: "56",
        maxLogRange: 4000,
        name: "Binance Smart Chain",
        rpcURL: "https://bsc-dataseed.binance.org/",
        tokenIcons:
            "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/ADDRESS/logo.png",
        // rpcURL: 'https://bsc.getblock.io/mainnet/',
        etherscanAPI: "https://api.bscscan.com/api",
        apikey: "XPBZUSEFMGI9XRVKUP9RGADIDGGXDJX1WN",
        explorer: "https://bscscan.com/token/",
        nativeSymbol: "BNB",
        multicall2: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
        defaultTokens: [SOLA_ADDR],
        defaultNFTs: ["0xce3ca8fb39e8142369edfa9cbaf1342d7ab31419"],
        customTokenIcons: {
            [SOLA_ADDR]:
                "https://cloudflare-ipfs.com/ipfs/QmS4ZhXnpmjuXbz6sBkAAkUHYsXLv6jGgEwYXxWkndu7eW",
        },

        swapLink: "https://pancakeswap.finance/swap?outputCurrency=",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/info/logo.png",
    },

    {
        key: "matic",
        id: "137",
        name: "Matic Network",
        rpcURL: "https://rpc-mainnet.maticvigil.com/",
        // rpcURL: 'https://rpc-mainnet.matic.network',
        // rpcURL: 'https://matic-mainnet.chainstacklabs.com',
        etherscanAPI: "https://api.polygonscan.com/api",
        nativeSymbol: "MATIC",
        explorer: "https://polygonscan.com/token/",
        maxLogRange: 900,
        multicall2: "0x37feDCaD0382bCE0890aA66dF1Bf27c1b20d6d56",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png",
    },
    {
        key: "xdai",
        id: "100",
        name: "xDai Network",
        nativeSymbol: "DAI",
        // rpcURL: 'https://blockscout.com/xdai/mainnet/api/eth-rpc',
        rpcURL: "https://rpc.xdaichain.com/",
        // etherscanAPI: 'https://api.polygonscan.com/api',
        explorer: "https://blockscout.com/xdai/mainnet/tokens/",
        // maxLogRange: 900,
        multicall2: "0x67dA5f2FfaDDfF067AB9d5F025F8810634d84287",
        swapLink: "https://app.honeyswap.org/#/swap?outputCurrency=",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/xdai/info/logo.png",
    },
    {
        key: "avax",
        id: "43114",
        name: "Avalanche Mainnet C-Chain",
        requireJSON: true,
        rpcURL: "https://api.avax.network/ext/bc/C/rpc",
        nativeSymbol: "AVAX",
        explorer: "https://cchain.explorer.avax.network/tokens/",
        multicall2: "0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/avalanche/info/logo.png",
    },
    {
        key: "poa",
        id: "99",
        name: "POA Network",
        rpcURL: "https://core.poa.network/",
        nativeSymbol: "POA",
        explorer: "https://blockscout.com/poa/core/tokens/",
        multicall2: "0x13fe01770a2a216F1f236Ed59c10D3a3BdCAa34E",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/poa/info/logo.png",
    },
    {
        key: "celo",
        id: "42220",
        name: "Celo",
        nativeSymbol: "CELO",
        explorer: "https://explorer.celo.org/tokens/",
        rpcURL: "https://forno.celo.org",
        requireJSON: true,
        multicall2: "0x86aAD62D1C36f4f92C8219D5C3ff97c3EF471bb8",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/celo/info/logo.png",
    },
    {
        key: "etc",
        id: "61",
        name: "Ethereum Classic",
        nativeSymbol: "ETC",
        explorer: "https://blockscout.com/etc/tokens/",
        etherscanAPI: "https://blockscout.com/etc/mainnet/api",
        maxLogRange: 900,
        rpcURL: "https://www.ethercluster.com/etc",
        multicall2: "0x51be3a92C56ae7E207C5b5Fd87F7798Ce82C1AC2",
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/classic/info/logo.png",
    },
    // {
    //     key: 'kcc',
    //     name: 'KuCoin Community Chain',
    //     rpcURL: 'https://rpc-mainnet.kcc.network',
    //     id: '321',
    //     nativeSymbol: 'KCS',
    //     explorer: 'https://explorer.kcc.io/en/token/',
    //     multicall2: ''
    // }

    // {
    //     key: 'bsctest',
    //     id: '56',
    //     name: 'Binance Smart Chain (Testnet)',
    //     rpcURL: 'https://bsc-dataseed.binance.org/',
    //     // rpcURL: 'https://bsc.getblock.io/mainnet/',
    //     etherscanAPI: 'https://api-testnet.bscscan.com/api',
    //     apikey: 'XPBZUSEFMGI9XRVKUP9RGADIDGGXDJX1WN',
    //     explorer: 'https://bscscan.com/token/',
    //     nativeSymbol: 'BNB',
    //     multicall2: '0xF588756d3a0B35313FfdfE4A9D8B8c3F806443af',
    //     tokenIconList:
    //         'https://github.com/trustwallet/assets/blob/master/blockchains/smartchain/tokenlist.json',
    //     defaultTokens: ['0x9ff25257a49c722f5a39f0942a0924bbc53b4f77'],
    //     defaultNFTs: ['0xa657e71cae4aab9c81d9a697c9657aa233cf1d5a'],
    //     swapLink: 'https://pancakeswap.finance/swap?outputCurrency=',
    // },
    {
        key: "ropsten",
        id: "3",
        name: "Ropsten Testnet",
        rpcURL: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        etherscanAPI: "https://api-ropsten.etherscan.io/api",
        apikey: "YMM3SCKG9P6ISXPX8YTSP32HRV5G2A3AVI",
        explorer: "https://ropsten.etherscan.io/token/",
        nativeSymbol: "Ropsten ETH",
        multicall2: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        ensreverse: "0x72c33B247e62d0f1927E8d325d0358b8f9971C68",
        isDefaultChain: true,

        defaultTokens: ["0x849fdfb65a513bb9f150b71f75e205b260ea2cd4"],
        customTokenIcons: {
            "0x849fdfb65a513bb9f150b71f75e205b260ea2cd4":
                "https://cloudflare-ipfs.com/ipfs/QmS4ZhXnpmjuXbz6sBkAAkUHYsXLv6jGgEwYXxWkndu7eW",
        },
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    },
    {
        key: "rinkeby",
        id: "4",
        name: "Rinkeby Testnet",
        rpcURL: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        etherscanAPI: "https://api-rinkeby.etherscan.io/api",
        apikey: "YMM3SCKG9P6ISXPX8YTSP32HRV5G2A3AVI",
        explorer: "https://rinkeby.etherscan.io/token/",
        nativeSymbol: "Rinkeby ETH",
        multicall2: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        ensreverse: "0x196eC7109e127A353B709a20da25052617295F6f",
        isDefaultChain: true,
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    },
    {
        key: "goerli",
        id: "5",
        name: "Goerli Testnet",
        nativeSymbol: "Goerli ETH",
        rpcURL: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        etherscanAPI: "https://api-goerli.etherscan.io/api",
        explorer: "https://goerli.etherscan.io/token/",
        multicall2: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        ensreverse: "0x333Fc8f550043f239a2CF79aEd5e9cF4A20Eb41e",
        isDefaultChain: true,
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    },
    {
        key: "kovan",
        id: "42",
        name: "Kovan Testnet",
        nativeSymbol: "Kovan ETH",

        rpcURL: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        etherscanAPI: "https://api-kovan.etherscan.io/api",
        explorer: "https://kovan.etherscan.io/token/",
        multicall2: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        isDefaultChain: true,
        logo: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    },
    // { key: 'cheapeth', id: '777', name: 'cheapETH', nativeSymbol: 'cETH' },
]

export function colorHash(str, s = 50, l = 80) {
    var h = 5831 << 2
    var i = 0
    for (i = 0; i < str.length; i++) {
        var ascii = str.charCodeAt(i)
        h = (h << 3) ^ h ^ ascii
    }
    return `hsl(${Math.abs(h & 0xffffffffff) % 360}, ${s}%, ${l}%)`
}

export const BSC = networks.find((k) => k.key === "bsc")

export const ERC721Methods = {
    tokenURI: "0xc87b56dd",
    ownerOf: "0x6352211e",
    name: "0x06fdde03",
    symbol: "0x95d89b41",
    balanceOf: "0x70a08231",
    tokenOfOwnerByIndex: "0x2f745c59",
}

export const ERC20Methods = {
    totalSupply: "0x18160ddd",
    balanceOf: "0x70a08231",
    transfer: "0xa9059cbb",
    allowance: "0xdd62ed3e",
    name: "0x06fdde03",
    symbol: "0x95d89b41",
    decimals: "0x313ce567",
}

export const MulticallMethods = {
    aggregate: "0x252dba42",
    tryAggregate: "0xbce38bd7",
    getBlockNumber: "0x42cbb15c",
    getEthBalance: "0x4d2301cc",
}

export const ENSResolverMethods = {
    getNames: "0xcbf8b66c",
}

export function toChecksumAddress(address, chainId = null) {
    if (typeof address !== "string") {
        return ""
    }
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        throw new Error(
            `Given address "${address}" is not a valid Ethereum address.`
        )
    }
    const stripAddress = address.replace("0x", "").toLowerCase()
    const prefix = chainId != null ? chainId.toString() + "0x" : ""
    const keccakHash = keccak256(prefix + stripAddress).replace(/^0x/i, "")
    let checksumAddress = "0x"

    for (let i = 0; i < stripAddress.length; i++) {
        checksumAddress +=
            parseInt(keccakHash[i], 16) >= 8
                ? stripAddress[i].toUpperCase()
                : stripAddress[i]
    }

    return checksumAddress
}

console.log(
    toChecksumAddress(
        "0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56".toLowerCase()
    )
)

function addressList(list) {
    return (
        toHex(32) +
        toHex(list.length) +
        list.map((k) => k.replace("0x", "").padStart(64, "0")).join("")
    )
}

function parseStringList(data) {
    return parseList(data.slice(64)).map((offset) =>
        _parseString(data.slice(128 + 2 * parseInt(offset, 16)))
    )
}

function parseBytes(data) {
    const numBytes = parseInt(data.slice(0, 32 * 2), 16)
    return data.slice(32 * 2, 32 * 2 + numBytes * 2)
}

export function parseString(data) {
    return _parseString(data.slice(64))
}
function _parseString(data) {
    return parseBytes(data).replace(/../g, (k) =>
        String.fromCharCode(parseInt(k, 16))
    )
}

function parseList(data) {
    const numItems = parseInt(data.slice(0, 64), 16)
    const list = []
    for (let i = 0; i < numItems; i++)
        list.push(data.slice(64 + i * 64, 64 + (i + 1) * 64))
    return list
}

function toHex(number) {
    return BigInt(number).toString(16).padStart(64, "0")
}

function parseNum(data) {
    return parseInt(data, 16)
}

async function runMultiCalls(network: Network, calls: [string, string][]) {
    let header = toHex(0) + toHex(32 + 32) + toHex(calls.length)
    let body = ""
    let offsetBase = calls.length * 32
    for (let [target, callData] of calls) {
        let data = callData.slice(2)
        let packet =
            target.slice(2).padStart(64, "0") + // target address
            toHex(64) +
            toHex(data.length / 2) +
            data.padEnd(64 * Math.ceil(data.length / 64), "0")
        header += toHex(offsetBase + body.length / 2)
        body += packet
    }

    let data = await ethRPC(
        network,
        "eth_call",
        {
            to: network.multicall2,
            data: MulticallMethods.tryAggregate + header + body,
        },
        "latest"
    )
    let result = data.slice(2)
    let resultCount = parseInt(result.slice(64, 128), 16)
    // console.log(result.slice(64, 128))
    let results = []
    for (let i = 0; i < resultCount; i++) {
        let offset = parseInt(result.slice(128 + 64 * i, 128 + 64 * i + 64), 16)
        let success = parseInt(
            result.slice(128 + offset * 2, 128 + offset * 2 + 64),
            16
        )
        let length = parseInt(
            result.slice(128 + 128 + offset * 2, 128 + 128 + offset * 2 + 64),
            16
        )
        let data = result.slice(
            128 + 64 * 3 + offset * 2,
            128 + 64 * 3 + offset * 2 + length * 2
        )
        results.push([!!success, data])
    }
    return results
}

async function cachedFetch(url: string) {
    const version = 1
    try {
        const { data, time, v } = JSON.parse(localStorage[url])
        if (v !== version || !time || Date.now() - time >= 10 * 60 * 1000)
            throw "too old"
        return data
    } catch (err) {
        return fetch(url)
            .then((k) => k.json())
            .then((data) => {
                localStorage[url] = JSON.stringify({
                    data,
                    time: Date.now(),
                    v: version,
                })
                return data
            })
    }
}

function ethRPC(network: Network, method: string, ...params: any[]) {
    return fetch(network.rpcURL, {
        method: "POST",
        headers: network.requireJSON
            ? {
                  "Content-Type": "application/json",
              }
            : {},
        body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            // id: Math.round(10000 * Math.random()),
            method: method,
            params: params,
        }),
    })
        .then((k) => k.json())
        .then((k) => {
            // if(k.error) throw k.error
            if (k.error) throw new Error(k.error.message)
            return k.result
        })
}

// Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)
const TransferTopicSignature =
    "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"

export async function fetchTokensAndNFTs(network: Network, account: string) {
    let logs = []
    if (network.maxLogRange) {
        const etherscanRequest = cachedFetch(
            network.etherscanAPI +
                "?" +
                new URLSearchParams({
                    module: "logs",
                    action: "getLogs",
                    fromBlock: "0",
                    toBlock: "latest",
                    topic0: TransferTopicSignature,
                    topic2: "0x" + account.slice(2).padStart(64, "0"),
                    topic0_2_opr: "and",
                    apikey: network.apikey,
                }).toString()
        )
        const getBlockNumberRequest = ethRPC(network, "eth_blockNumber").then(
            (data) =>
                ethRPC(network, "eth_getLogs", {
                    fromBlock:
                        "0x" +
                        Math.max(
                            0,
                            parseInt(data.slice(2), 16) - network.maxLogRange
                        ).toString(16),
                    toBlock: "latest",
                    topics: [
                        TransferTopicSignature,
                        null,
                        "0x" + account.slice(2).padStart(64, "0"),
                    ],
                })
        )
        logs = [
            ...(await etherscanRequest).result,
            ...(await getBlockNumberRequest),
        ]
    } else {
        logs = await ethRPC(network, "eth_getLogs", {
            fromBlock: "0x1",
            toBlock: "latest",
            topics: [
                TransferTopicSignature,
                null,
                "0x" + account.slice(2).padStart(64, "0"),
            ],
        })
    }
    logs = logs.slice(-100)

    console.log(logs)
    const calls = []
    calls.push(
        network.multicall2 +
            MulticallMethods.getEthBalance +
            account.slice(2).padStart(64, "0")
    )
    if (network.defaultTokens) {
        for (let tok of network.defaultTokens) {
            logs.push({
                address: tok,
                topics: [
                    TransferTopicSignature,
                    "0x" + account.slice(2).padStart(64, "0"),
                    "0x" + account.slice(2).padStart(64, "0"),
                ],
            })
        }
    }
    for (let log of logs) {
        calls.push(log.address + ERC20Methods.totalSupply)
        calls.push(log.address + ERC20Methods.decimals)
        calls.push(log.address + ERC20Methods.name)
        calls.push(log.address + ERC20Methods.symbol)
        calls.push(
            log.address +
                ERC20Methods.balanceOf +
                account.slice(2).padStart(64, "0")
        )
        // for NFTs, the tokenId is also part of the indexed event
        if (log.topics[3]) {
            calls.push(
                log.address + ERC721Methods.ownerOf + log.topics[3].slice(2)
            )
            calls.push(
                log.address + ERC721Methods.tokenURI + log.topics[3].slice(2)
            )
        }
    }
    // console.log(calls)
    const map = await multicallMap(network, calls)

    return {
        logs: logs,
        results: map,
    }
}

export async function getRecentTransactions(network: Network) {
    const blockNumber = await ethRPC(network, "eth_blockNumber")
    const numRecentBlocks = 10
    const recentLogs = await ethRPC(network, "eth_getLogs", {
        fromBlock:
            "0x" +
            Math.max(
                0,
                parseInt(blockNumber.slice(2), 16) - numRecentBlocks
            ).toString(16),
        toBlock: "latest",
        topics: [TransferTopicSignature],
    })
    return recentLogs
}

export async function multicallMap(network: Network, calls: string[]) {
    const callTuples: [string, string][] = Array.from(new Set(calls)).map(
        (k) => [k.slice(0, 42), k.slice(42)]
    )
    const results = await runMultiCalls(network, callTuples)
    const map = {}
    for (let i = 0; i < callTuples.length; i++)
        map[callTuples[i].join("")] = results[i][1]
    return map
}

export async function getRecentTransfers(network: Network) {
    const allTxs = await getRecentTransactions(network)
    const txs = allTxs
        .filter(
            (k) =>
                k.topics.length === 4 &&
                !k.topics[1].endsWith("000") &&
                !k.topics[2].endsWith("000") &&
                k.topics[3]
        )
        .slice(-30)
    const calls = []
    for (let tx of txs) {
        calls.push(tx.address + ERC20Methods.decimals)
        calls.push(tx.address + ERC20Methods.name)
        calls.push(tx.address + ERC20Methods.symbol)
        if (tx.topics[3]) {
            calls.push(
                tx.address + ERC721Methods.tokenURI + tx.topics[3].slice(2)
            )
        }
    }
    // calls.push([
    //     network.ensreverse,
    //     ENSResolverMethods.getNames +
    //         addressList([
    //             '0xffd1ac3e8818adcbe5c597ea076e8d3210b45df5',
    //             '0x18ae9fc06bed0637b1d46063d6b7af1a4f97b02c',
    //         ]),
    // ])
    const map = await multicallMap(network, calls)
    return txs.map((tx) => {
        const addr = tx.address
        return {
            from: "0x" + tx.topics[1].slice(-40),
            to: "0x" + tx.topics[2].slice(-40),

            token: {
                name: parseString(map[addr + ERC20Methods.name]),
                decimals: parseNum(map[addr + ERC20Methods.decimals]),
                symbol: parseString(map[addr + ERC20Methods.symbol]),
                URI:
                    tx.topics[3] &&
                    parseString(
                        map[
                            addr +
                                ERC721Methods.tokenURI +
                                tx.topics[3].slice(2)
                        ]
                    ),
            },
        }
    })
}

export async function addToWallet({
    network,
    address,
    symbol,
    decimals,
    icon,
}) {
    const hexNet = "0x" + parseInt(network.id).toString(16)

    const chainId = await window.ethereum.request({
        method: "eth_chainId",
        params: [],
    })
    if (chainId !== hexNet) {
        if (!network.isDefaultChain) {
            const connectionParam = {
                chainId: hexNet,
                chainName: network.name,
                nativeCurrency: {
                    name: network.nativeSymbol,
                    symbol: network.nativeSymbol,
                    decimals: 18,
                },
                rpcUrls: [network.rpcURL],
                blockExplorerUrls: ["https://bscscan.com/"],
            }
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [connectionParam],
            })
        } else {
            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        {
                            chainId: hexNet,
                        },
                    ],
                })
            } catch (err) {
                console.error(err)
            }
        }
        const chainId2 = await window.ethereum.request({
            method: "eth_chainId",
            params: [],
        })

        if (chainId2 !== hexNet) {
            return alert("Please switch Metamask to " + network.name)
        }

        await delay(1000)
    }

    await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
            type: "ERC20",
            options: {
                address: address,
                symbol: symbol,
                decimals: decimals,
                image: icon,
            },
        },
        id: Math.round(Math.random() * 100000),
    })
    // (err, added) => {
    //     if (added) {
    //         console.log("Added", added)
    //     } else {
    //         alert(`Unable to add token`)
    //     }
    // }
    // )
}

export function uniqBy<T>(list: T[], fn: (item: T) => string): T[] {
    let result: Record<string, T> = {}
    for (let k of list) result[fn(k)] = k
    return Object.values(result)
}

export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
